'use client'
import {
  resetSignUp,
  setActiveTab,
  setOpen,
  setStage,
} from '@/app/globalRedux/signUp/signUp'
import { RootState } from '@/app/globalRedux/store'
import useLocalizedRouter from '@/app/hooks/useLocalizedRouter'
import { useTranslation } from '@/app/i18n/client'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import { Skeleton } from '@/components/ui/skeleton'
import { isValidResetPasswordToken } from '@/services/loginService'
import dynamic from 'next/dynamic'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'sonner'
import LoginModalLoading from '../Skeletons/LoginModalLoading'
const MemberShipPricing = dynamic(() => import('./MemberShipPricing'))
const RightModal = dynamic(() => import('./RightModal'), {
  loading: () => <LoginModalLoading />,
})
const BaseModal = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { t } = useTranslation()
  const pathName = usePathname()
  const { open } = useSelector((state: RootState) => state.signUp)
  const searchParams = useSearchParams()
  const user = useSelector((state: RootState) => state.user)
  const currentUrl = typeof window !== 'undefined' ? window.location.href : ''
  useEffect(() => {
    if (
      searchParams.get('page') == 'validate-mail' ||
      searchParams.get('mail-modal') == 'open'
    ) {
      dispatch(setOpen(true))
      // company sign-up
      if (searchParams.get('signup') == 'company') {
        dispatch(setStage(1))
        dispatch(setActiveTab('company'))
        dispatch(setOpen(true))
      }
    }
    if (searchParams.get('login-modal') == 'open') {
      if (!user?.loggedIn) dispatch(setOpen(true))
    }
    if (
      !searchParams.get('page') &&
      !(searchParams.get('mail-modal') == 'open')
    ) {
      dispatch(setOpen(false))
    }
  }, [searchParams])
  useEffect(() => {
    const fetchValidTaken = async () => {
      const validToken: { valid: boolean } = await isValidResetPasswordToken(
        searchParams.get('token') ?? ''
      )

      if (validToken.valid == true) {
        dispatch(setOpen(true))
      } else if (validToken.valid == false) {
        toast.warning(t('Token is Not Valid'))
      }
    }

    if (
      searchParams.get('page') == 'forget_password' ||
      searchParams.get('page') == 'set_password'
    ) {
      setOpen(false)
      fetchValidTaken()
    }
  }, [searchParams])

  const openController = (e: any) => {
    if (pathName.includes('member/profile')) {
      router.push('/')
      return
    }
    if (!e) {
      const url = currentUrl.split('?')[0]
      window.history.pushState({ path: url }, '', url)
    }
    dispatch(setOpen(!open))
    if (open) {
      dispatch(resetSignUp())
    }
  }

  return (
    <>
      <Dialog open={open} onOpenChange={openController}>
        <SignUpModalorPricing />
      </Dialog>
    </>
  )
}

export default BaseModal

export const LeftModal = () => {
  const pushWithLocale = useLocalizedRouter()

  const { t } = useTranslation()

  return (
    <div className="w-[439px] rounded-l-lg bg-no-repeat bg-cover bg-center bg-origin-border  text-center p-5 pt-[90px] flex flex-col demo-background-image mobile:pt-[1rem]  mobile:rounded-[0px] mobile-width mobile:h-[220px] h-[100%]">
      <h2 className="text-[24px] font-ns_bold px-[28px] mobile:text-[18px]">
        {t('largest Leading B2B Website for Fruit and Vegetables')}
      </h2>
      <h4 className=" text-[14px] font-ns_semibold mt-[20px] px-[28px] mobile:text-[13px]">
        {t('Register your company and unlock exclusive services on Fructidor')}
      </h4>
      <div className="mt-[31px] flex justify-center">
        <div
          className="text-[16px] text-[#fff] bg-primary px-[21px] py-[9px] rounded-[5px] w-fit font-ns_bold cursor-pointer"
          onClick={() => {
            pushWithLocale('/about-us/contact-us')
          }}
        >
          {t('Contact Us')}
        </div>
      </div>
    </div>
  )
}

const CommonDialogContent = () => {
  return (
    <DialogContent
      className="border-0 p-0 !max-w-[1080px] bg-[#fff] overflow-scroll mobile:max-h-[90dvh] sm:max-h-[90dvh]"
      onInteractOutside={(e) => {
        e.preventDefault()
      }}
    >
      <div className="flex mobile:flex-col-reverse">
        <LeftModal />
        <div className=" w-full m basis-7/12 min-h-[765px] max-h-[765px] flex flex-col justify-center overflow-scroll mobile:w-[inherit] mobile:min-h-[inherit]  mobile:py-[15px] mobile:basis-[auto]">
          <Suspense fallback={<Skeleton className="w-[1000px] h-[1000px]" />}>
            <RightModal />
          </Suspense>
        </div>
      </div>
    </DialogContent>
  )
}

const SignUpModalorPricing = () => {
  const searchparams = useSearchParams()

  if (searchparams.get('page') == 'pricing') {
    return <MemberShipPricing />
  }
  return <CommonDialogContent />
}
