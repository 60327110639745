'use client'

import { useTranslation } from '@/app/i18n/client'
import { useEffect, useState } from 'react'
import NewsLetterModal from './NewsLetterModal'

const NewsLetter = () => {
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    const isValidEmail = (email: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(email)
    }
    setIsEmailValid(isValidEmail(email))
  }, [email])

  return (
    <div className="mobile:w-full">
      <div className="h-[320px] bg-[#fff5de] flex justify-center items-center flex-col mobile:py-[40px] mobile:text-center">
        <div className="text-[26px] font-ns_bold mb-[21px] mobile:text-[22px]">
          {t('JOIN OUR NEWSLETTER FOR FREE')}
        </div>
        <div className="text-center mb-[41px] mobile:text-[14px]">
          {t(
            'Register now to receive latest News and Opportunities of fruit-vegetables market,'
          )}{' '}
          <br />
          {t('Don’t worry, we provide an email preference center')}
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <div className="w-[527px] h-[50px] bg-[white] flex rounded-[5px] shadow-[0_0_27px_0_rgba(0,0,0,0.15)] mobile:w-[350px]">
            <input
              type="email"
              placeholder={t('Enter your email address')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="inputField1 w-full pl-[24px] text-sm rounded-[5px] outline-none"
            />
            <div className="flex items-center newsletter-input">
              <NewsLetterModal
                email={email}
                setEmail={setEmail}
                isEmailValid={isEmailValid}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewsLetter
