export interface RecaptchaResponse {
  valid: boolean
}

export const verifyRecaptcha = async (
  token: string
): Promise<RecaptchaResponse> => {
  return { valid: true }
  try {
    const response = await fetch(`${process.env.API_URL}/verify_recaptcha`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
      credentials: 'include',
    })

    if (!response.ok) {
      console.error('Network response was not ok')
      return { valid: false }
    }

    const data = await response.json()

    if (data.valid !== undefined) {
      return { valid: data.valid }
    } else {
      console.error('Invalid response shape')
      return { valid: false }
    }
  } catch (error) {
    console.error('Error verifying reCAPTCHA:', error)
    return { valid: false }
  }
}
