import * as React from 'react'

import { cn } from '@/lib/utils'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const numberInputOnWheelPreventChange = (e: any) => {
  e.target.blur()
  e.stopPropagation()
  setTimeout(() => {
    e.target.focus()
  }, 0)
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-[3px] !border-2 !border-[#e0e0e0] cursor-pointer bg-background px-2 py-3 text-sm  file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-placeholder_text disabled:cursor-not-allowed disabled:opacity-50 focus:outline-none',
          className
        )}
        ref={ref}
        {...props}
        onWheel={numberInputOnWheelPreventChange}
      />
    )
  }
)
Input.displayName = 'Input'

export { Input }
