import { useCallback, useEffect, useState } from 'react'

const SITE_KEY = '6LeBpyQqAAAAAMPPf9O8gjOAp2GTcVf1OxgHTpmj'

declare global {
  interface Window {
    grecaptcha: {
      render?: (container: HTMLElement, options: any) => number
      reset?: (widgetId: number) => void
      execute: (siteKey: string, options: { action: string }) => Promise<string>
      ready: (callback: () => void) => void
    }
  }
}

const useRecaptchaV3 = () => {
  const [isRecaptchaReady, setIsRecaptchaReady] = useState<boolean>(false)

  useEffect(() => {
    if (!window.grecaptcha) {
      const script = document.createElement('script')
      script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`
      script.async = true
      script.defer = true
      script.onload = () => setIsRecaptchaReady(true)
      document.head.appendChild(script)
    } else {
      setIsRecaptchaReady(true)
    }
  }, [])

  const executeRecaptcha = useCallback(
    async (action: string): Promise<string | null> => {
      if (isRecaptchaReady && window.grecaptcha) {
        try {
          await new Promise<void>((resolve) => window.grecaptcha.ready(resolve))
          return await window.grecaptcha.execute(SITE_KEY, { action })
        } catch (error) {
          console.error('Error executing reCAPTCHA:', error)
          return null
        }
      }
      return null
    },
    [isRecaptchaReady]
  )

  return executeRecaptcha
}

export default useRecaptchaV3
