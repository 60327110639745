import { Skeleton } from '@/components/ui/skeleton'

const LoginModalLoading = () => {
  return (
    <div className="flex flex-col gap-y-6 rounded-lg px-20 justify-center items-center mobile:px-[20px] mobile:pt-[15px]">
      <Skeleton className="flex h-[32px] w-[240px] mb-4 mobile:m-0 " />
      <div className="w-full flex flex-col gap-y-6">
        <div className="grid w-full items-center gap-1.5">
          <div className="grid items-center gap-3 ">
            <Skeleton className="flex h-[32px] w-[100px] mb-4 mobile:m-0 " />
            <Skeleton className="flex h-[32px] w-[400px] mb-4 mobile:m-0 " />
            <Skeleton className="flex h-[32px] w-[100px] mb-4 mobile:m-0 " />
            <Skeleton className="flex h-[32px] w-[400px] mb-4 mobile:m-0 " />
            <div className=" flex flex-row justify-between w-[400px]">
              <Skeleton className="flex h-[32px] w-[100px] mb-4 mobile:m-0 " />
              <Skeleton className="flex h-[32px] w-[100px] mb-4 mobile:m-0 " />
            </div>
            <Skeleton className="flex h-[32px] w-[400px] mb-4 mobile:m-0 " />
            <div className=" flex flex-row justify-center items-center w-[400px]">
              <Skeleton className="flex h-[32px] w-[200px] mb-4 mobile:m-0 " />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginModalLoading
